import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=67fc71c6&"
import script from "./footer.vue?vue&type=script&lang=js&"
export * from "./footer.vue?vue&type=script&lang=js&"
import style0 from "./footer.vue?vue&type=style&index=0&id=67fc71c6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconOrnament: require('/opt/yamal.ru/src/components/icon/ornament.vue').default,BaseLogo: require('/opt/yamal.ru/src/components/base/logo.vue').default,RzContainer: require('/opt/yamal.ru/node_modules/razlet-ui/src/components/container/index.vue').default})
